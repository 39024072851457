import EntryComponent from './entry/entry.module';

import controller from './club.component';
import template from './club.html';

import { forEach } from 'lodash-es';

export default angular
    .module('app.components.club', [
        EntryComponent,
    ])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.activities', {
                url: '/activities?tab&date',
                data: {
                    flush: true,
                },
                params: {
                    tab: {
                        type: 'string',
                        value: 'activities',
                    }
                },
                component: 'club',
                resolve: {
                    activitiesResolver: (User, Club, $filter, $transition$) => {
                        "ngInject";

                        let currentDate = moment();

                        let date = $transition$.params().date;
                        let formatDate = $filter('formatDate');

                        if (currentDate > moment(User.getUser().arrivalAt) && date == null) {
                            date = formatDate(currentDate, 'sql');
                        } else if (currentDate < moment(User.getUser().arrivalAt) && date == null) {
                            date = formatDate(User.getUser().arrivalAt, 'sql');
                        }

                        return Club.getSlotsActivities(date);
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            })
            .state('auth.club', {
                url: '/club?tab&date',
                data: {
                    flush: true,
                },
                params: {
                    tab: {
                        type: 'string',
                        value: 'posts',
                    }
                },
                component: 'club',
                resolve: {
                    postsResolver: (Club) => {
                        "ngInject";

                        return Club.getPosts();
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            })
            .state('auth.club.category', {
                url: '/category/:category',
                params: {
                    tab: {
                        type: 'string',
                        value: 'category',
                    }
                },
                views: {
                    '@auth': 'club',
                },
                resolve: {
                    postsResolver: (Club, $transition$) => {
                        "ngInject";

                        return Club.getPosts().then((categories) => {
                            let foundCategory = null;

                            forEach(categories, (category, key) => {
                                foundCategory = (key === $transition$.params().category) ? category : null;

                                if (foundCategory) {
                                    return false;
                                }
                            });

                            return foundCategory;
                        });
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('club', {
        bindings: {
            activitiesResolver: '<',
            postsResolver: '<',
        },
        template,
        controller,
    })
    .name;
