class HttpService {
    constructor($http, $filter, Config) {
        "ngInject";

        this.$http = $http;

        this.translate = $filter('translate');

        this.Config = Config;

        this.pendingRequests = [];
    }

    get(url, config) {
        let fullUrl = this.getFullUrl(url, config);
        this.addPendingRequest(fullUrl);

        if (config && this.Config.isMsie) {
            config.cache = false;
        }

        return this.$http.get(url, config).finally(()=> {
            this.deletePendingRequest(fullUrl);
        });
    }

    post(url, data, config) {
        let fullUrl = this.getFullUrl(url, config);
        this.addPendingRequest(fullUrl);

        return this.$http.post(url, data, config).finally(()=> {
            this.deletePendingRequest(fullUrl);
        });
    }

    put(url, data, config) {
        let fullUrl = this.getFullUrl(url, config);
        this.addPendingRequest(fullUrl);

        return this.$http.put(url, data, config).finally(()=> {
            this.deletePendingRequest(fullUrl);
        });
    }

    patch(url, data, config) {
        let fullUrl = this.getFullUrl(url, config);
        this.addPendingRequest(fullUrl);

        return this.$http.patch(url, data, config).finally(()=> {
            this.deletePendingRequest(fullUrl);
        });
    }

    delete(url) {
        this.addPendingRequest(url);

        return this.$http.delete(url).finally(()=> {
            this.deletePendingRequest(url);
        });
    }

    hasPendingRequest() {
        return this.pendingRequests.length > 0;
    }

    addPendingRequest(url) {
        this.pendingRequests.push(url);
    }

    deletePendingRequest(url) {
        let pendingRequestIndex = this.pendingRequests.indexOf(url);

        if (pendingRequestIndex !== -1) {
            this.pendingRequests.splice(pendingRequestIndex, 1);
        }
    }

    getFullUrl(url, config) {
        if (config && config.hasOwnProperty('params')) {
            let serializedParams = this.$http.defaults.paramSerializer(config.params);

            if (serializedParams.length > 0) {
                url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
            }

            return url;
        }

        return url;
    }
}

export default angular
    .module('app.services.http', [])
    .service('Http', HttpService)
    .name;
