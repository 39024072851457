class MainController {
    constructor(Updater, Config, $state, $filter) {
        "ngInject";

        this.Updater = Updater;
        this.Config = Config;

        this.$state = $state;
        this.$translate = $filter('translate');
    }

    $onInit() {
    }

    stateNameClass() {
        let stateName = this.$state.current.name.replace(/\./g, '-');

        if (stateName.indexOf('admin') !== -1) {
            return `admin ${stateName}`;
        }

        return stateName;
    }

    forceReload() {
        window.location.reload(true);
    }
}

export default MainController;
