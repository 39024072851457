class LoaderController {
    constructor(Http, $filter) {
        "ngInject";

        this.Http = Http;

        this.$translate = $filter('translate');
    }
}

export default LoaderController;
