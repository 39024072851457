import controller from './summary.component';
import template from './summary.html';

export default angular
    .module('app.components.summary', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.summary', {
                url: '/summary',
                data: {
                    flush: true,
                },
                component: 'summary',
                resolve: {
                    slotsResolver: (Calendar) => {
                        "ngInject";

                        return Calendar.getUserSlots();
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('summary', {
        bindings: {
            slotsResolver: '<',
        },
        template,
        controller,
    })
    .name;
