import controller from './loader.component';
import template from './loader.html';

export default angular
    .module('app.core.loader', [])
    .component('loader', {
        bindings: {},
        template,
        controller,
    })
    .name;
