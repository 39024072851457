import angular from 'angular'
import { BinaryHeap, CacheFactory, defaults, utils } from 'cachefactory'

utils.equals = angular.equals;
utils.isObject = angular.isObject;
utils.fromJson = angular.fromJson;

function BinaryHeapProvider () {
    this.$get = function () {
        return BinaryHeap;
    }
}

function CacheFactoryProvider () {
    this.defaults = defaults;
    this.defaults.storagePrefix = 'angular-cache.caches.';

    this.$get = function ($q) {
        "ngInject";

        utils.Promise = $q;
        return new CacheFactory();
    }
}

export default angular
    .module('app.core.cache', [])
    .provider('BinaryHeap', BinaryHeapProvider)
    .provider('CacheFactory', CacheFactoryProvider)
    .name
