class ModalController {
    constructor(Modal, Config) {
        "ngInject";

        this.Modal = Modal;
        this.Config = Config;
    }

    $onInit() {
        let {
            isSidebar = false,
            isFullscreen = false,
            isDesktopHeight80 = false,
            canScroll = true,
            clickOutsideToDismiss = true,
            hasPaddedContent = false,
            hasFlexContent = false,
            showPrevButton = false,
            zIndex = 300,
            prevCallback = () => {},
            closeCallback = () => {},
        } = this.mdOptions || {};

        this.Modal.register({
            name: this.mdName,
            isFullscreen,
            isDesktopHeight80,
            isSidebar,
            prevCallback,
            showPrevButton,
            closeCallback,
            canScroll,
            clickOutsideToDismiss,
            hasPaddedContent,
            hasFlexContent,
            zIndex,
        });
    }

    $onDestroy() {
        this.Modal.destroy(this.mdName);
    }

    self() {
        return this.Modal.get(this.mdName);
    }

    close() {
        this.Modal.close(this.mdName);
    }

    clickOutsideToDismiss() {
        if (this.self().clickOutsideToDismiss) {
            this.Modal.close(this.mdName);
        }
    }
}

export default ModalController;
