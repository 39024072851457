class ClubService {
    constructor(Http, CacheFactory, Config, $q) {
        "ngInject";

        this.Http = Http;
        this.CacheFactory = CacheFactory;
        this.Config = Config;
        this.$q = $q;

        this.cache = this.CacheFactory.exists('club');

        if (this.cache) {
            this.cache = this.CacheFactory.get('club');
        }
        else {
            this.cache = this.CacheFactory.createCache('club');
        }

        this.resetCache();
    }

    getPosts() {
        return this.Http.get('/posts', {
            cache: this.cache,
        }).then((response) => response.data);
    }

    getActivities() {
        return this.Http.get(`/activities`, {
            cache: this.cache,
        }).then((response) => response.data);
    }

    getSlotsActivities(date = null) {
        return this.Http.get(`/slots/activities?date=${date}`).then((response) => response.data);
    }

    getCache() {
        return this.cache;
    }

    resetCache() {
        if (typeof this.cache === 'object') {
            this.cache.removeAll();
        }
    }
}

export default angular
    .module('app.services.club', [])
    .service('Club', ClubService)
    .name;
