import controller from './entry.component';
import template from './entry.html';

import { forEach, find } from 'lodash-es';

export default angular
    .module('app.components.club.entry', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.club.entry', {
                url: '/entry/:slug/:id',
                params: {
                    slug: {
                        type: 'string',
                    },
                    id: {
                        type: 'int',
                    },
                },
                views: {
                    '@auth': 'entry'
                },
                resolve: {
                    entryResolver: (Club, $transition$) => {
                        switch($transition$.params().slug) {
                            case 'activities':
                                return Club.getActivities().then((activities) => {
                                    return activities.find((activity) => {
                                        return activity.id === $transition$.params().id;
                                    });
                                });
                            case 'posts':
                            default:
                                return Club.getPosts().then((categories) => {
                                    let foundPost = null;

                                    forEach(categories, (category) => {
                                        foundPost = find(category.posts, (post) => {
                                            return post.id === $transition$.params().id;
                                        });

                                        if (foundPost) {
                                            return false;
                                        }
                                    });

                                    return foundPost;
                                });
                        }
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            })
            .state('auth.calendar.entry', {
                url: '/entry/:slug/:id',
                params: {
                    slug: {
                        type: 'string',
                    },
                    id: {
                        type: 'int',
                    },
                },
                views: {
                    '@auth': 'entry'
                },
                resolve: {
                    entryResolver: (Calendar, $transition$) => {
                        return Calendar.getPlaces().then((places) => {
                            let entry = null;

                            let row = places.find((row) => {
                                return row.type === $transition$.params().slug;
                            });

                            entry = row.items.find((item) => {
                                switch(row.type) {
                                    case 'KID':
                                        return item.clubData.id === $transition$.params().id;
                                    case 'RESTAURANT':
                                    case 'PLACE':
                                    default:
                                        return item.id === $transition$.params().id;
                                }
                            });

                            if ($transition$.params().slug === 'KID') {
                                if (entry.slotable.length > 0) {
                                    entry = entry.slotable[0];
                                }
                                else {
                                    entry = entry.clubData;
                                }
                            }

                            return entry;
                        });
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('entry', {
        bindings: {
            entryResolver: '<',
        },
        template,
        controller,
    })
    .name;
