import { has, get, set, omit, forEach, some, filter, orderBy, first } from 'lodash-es';

export default class ModalService {
    constructor($document) {
        "ngInject";
        
        this.$document = $document;

        this.modals = {};
    }

    register(modalOptions) {
        modalOptions.isOpen = false;

        set(this.modals, modalOptions.name, modalOptions);
    }

    get(modalName) {
        return get(this.modals, modalName);
    }

    isOpen(modalName) {
        if (has(this.modals, modalName)) {
            return this.get(modalName).isOpen;
        }

        return false;
    }

    hasModalOpened() {
        return some(this.modals, (modal) => {
            return modal.isOpen;
        });
    }

    open(modalName) {
        if (has(this.modals, modalName)) {
            this.get(modalName).isOpen = true;

            let scrollable = this.$document[0].querySelector(`modal[md-name="${modalName}"] .scrollable`);

            if (scrollable !== null) {
                scrollable.scrollTop = 0;
            }
        }
    }

    close(modalName) {
        if (has(this.modals, modalName)) {
            this.get(modalName).closeCallback();
            this.get(modalName).isOpen = false;
        }
    }

    closeAll() {
        forEach(this.modals, (modal) => {
            modal.isOpen = false;
        });
    }

    closeLastModal() {
        if (!this.hasModalOpened()) {
            return;
        }

        let lastOpenedModal = first(orderBy(filter(this.modals, (modal) => {
            return modal.isOpen;
        }), ['zIndex'], ['asc']));

        if (!lastOpenedModal) {
            return;
        }

        if (!lastOpenedModal.clickOutsideToDismiss) {
            return;
        }

        this.close(lastOpenedModal.name);
    }

    destroy(modalName) {
        this.modals = omit(this.modals, modalName);
    }
}
