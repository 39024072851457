class Calendar {
    constructor(Http, CacheFactory, Config, $filter) {
        "ngInject";

        this.Http = Http;
        this.CacheFactory = CacheFactory;
        this.Config = Config;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');

        this.meals = [
            {
                type: 0,
                name: this.$translate('CALENDAR_RESTAURANTS_BREAKFAST'),
            },
            {
                type: 1,
                name: this.$translate('CALENDAR_RESTAURANTS_LUNCH'),
            },
            {
                type: 2,
                name: this.$translate('CALENDAR_RESTAURANTS_DINNER'),
            }
        ];

        this.cache = this.CacheFactory.exists('calendar');

        if (this.cache) {
            this.cache = this.CacheFactory.get('calendar');
        }
        else {
            this.cache = this.CacheFactory.createCache('calendar');
        }

        this.resetCache();
    }

    getAllUserSlots() {
        return this.Http.get('/restaurant/slots', {
            cache: this.cache,
        }).then((response) => response.data);
    }

    getPlaces() {
        return this.Http.get('/places', {
            cache: this.cache,
        }).then((response) => response.data);
    }

    getSlots(id, kidId = null) {
        return this.Http.get(`/slots/${id}?kidId=${kidId}`)
            .then((response) => response.data);
    }

    getUserSlots() {
        return this.Http.get(`/user/slots`)
            .then((response) => response.data);
    }

    postSlot(id, seats, kidId = null, isAdult = null) {
        return this.Http.post(`/slot/${id}?kidId=${kidId}`, {
            seats: seats,
            isAdult: isAdult,
        })
            .then((response) => response.data);
    }

    deleteSlot(id, kidId = null) {
        return this.Http.delete(`/slot/${id}?kidId=${kidId}`)
            .then((response) => response.data);
    }

    getGroupsAndSlotsForUserSlot(date, type) {
        return this.Http.get(`/restaurant/slots/date?date=${date}&type=${type}`).then((response) => {
            return response.data;
        });
    }

    getMeals() {
        return this.meals;
    }

    getCache() {
        return this.cache;
    }

    resetCache() {
        if (typeof this.cache === 'object') {
            this.cache.removeAll();
        }
    }
}

export default angular
    .module('app.services.calendar', [])
    .service('Calendar', Calendar)
    .name;
