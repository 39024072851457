import controller from './main.component';
import template from './main.html';

export default angular
    .module('app.core.main', [])
    .component('main', {
        bindings: {},
        template,
        controller,
    })
    .name;
