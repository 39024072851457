import { toString } from 'lodash-es';

class ProfileFormController {
    constructor(User, Navigation, Onboarding, Http, Config, $timeout, $state, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Onboarding = Onboarding;
        this.Http = Http;
        this.Config = Config;
        this.edit = false;

        this.$timeout = $timeout;
        this.$state = $state;
        this.$translate = $filter('translate');
        this.showAllowToUseEmail = false;

        this.now = moment();

        this.plans = [
            {
                id: 1,
                name: this.$translate('PROFILE_PLAN_RENTAL'),
            },
            {
                id: 2,
                name: this.$translate('PROFILE_PLAN_HALF_BOARD'),
            },
            {
                id: 3,
                name: this.$translate('PROFILE_PLAN_FULL_BOARD'),
            },
        ];

        this.plan = this.plans[0].id;
        this.allowToUseEmail = false;

        this.personNumber = 1;
    }

    $onInit() {
        this.edit = this.edit || false;


        this.Navigation.setTitle(this.edit ? this.$translate('PROFILE_FORM_EDIT_TITLE') : this.$translate('PROFILE_FORM_TITLE'));

        let detail = this.User.getUser();

        if (detail) {
            this.plan = detail.mode;
            this.allowToUseEmail = detail.allowToUseEmail;
            this.name = detail.lastName;
            this.email = detail.email;
            this.arrivalDate = moment(detail.arrivalAt);
            this.leaveDate = moment(detail.leaveAt);
            this.personNumber = detail.personNumber;
            this.adultsCount = detail.adultsCount;
            this.childrenCount = detail.childrenCount;
            this.site = toString(detail.site.id);
            this.siteName = detail.site.name;
        }

        this.Http.get('/sites').then((response) => {
            this.sites = response.data.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
        });
    }

    getPlanName(planId) {
        return this.plans.find((plan) => {
            return plan.id === planId
        }).name;
    }

    submit() {
        this.Http.post('/client/detail', {
            arrivalDate: this.arrivalDate.format('DD/MM/YYYY'),
            leaveDate: this.leaveDate.format('DD/MM/YYYY'),
            personNumber: this.personNumber,
            plan: this.plan,
            site: this.site,
            name: this.name,
            email: this.email,
            allowToUseEmail: this.allowToUseEmail,
        }).then(() => {
            if (this.edit) {
                this.afterSubmit();
            }
            else {
                this.Onboarding.show().then(() => {
                    this.afterSubmit();
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    afterSubmit() {
        this.User.resetCache();

        this.$state.go(this.Config.calendar_state_name, {}, { reload: true, inherit: false });
    }
}

export default ProfileFormController;
