import moment from 'moment';

import { isNil } from 'lodash-es';

class AlertController {
    constructor($scope, $element, $timeout, $animate, $filter, Alert) {
        "ngInject";

        this.$scope = $scope;
        this.$element = $element;
        this.$timeout = $timeout;
        this.$animate = $animate;
        this.$translate = $filter('translate');

        this.promptValue = '';

        this.alertService = Alert;

        this.isBusy = false;
        this.alert = null;

        this.onAlertAdded = this.$scope.$on('alert:added', () => {
            this.$scope.$evalAsync(() => {
                this.processPending();
            });
        });

        this.onAlertHide = this.$scope.$on('alert:toast:hide', () => {
            if (this.isToast() && this.alert.duration === -1) {
                this.clear();
            }
        });

        this.$animate.on('leave', this.$element, (element) => {
            if (element.hasClass('ng-animate')) {
                return;
            }

            this.$scope.$apply(() => {
                this.isBusy = false;
                this.processPending();
            });
        });
    }

    $onDestroy() {
        this.onAlertAdded();
        this.onAlertHide();

        this.$animate.off('leave', this.$element);
    }

    processPending() {
        if (!this.isBusy) {
            this.alert = this.alertService.alerts.shift();

            if (!this.alert) {
                this.alert = null;

                return;
            }

            if (this.isPromptDate() && this.alert.defaultValue) {
                this.promptValue = new moment(this.alert.defaultValue);
            }

            if ((this.isPrompt() || this.isPromptNumber()) && this.alert.defaultValue != null) {
                this.promptValue = this.alert.defaultValue;
            }

            if (this.isPromptNumber() && !this.alert.defaultValue) {
                this.promptValue = 0;
            }

            this.isBusy = true;

            if (this.isToast() && this.alert.duration !== -1) {
                this.$timeout(() => {
                    this.clear();
                }, this.alert.duration);
            }
        }
    }

    isConfirm() {
        return this.alert && this.alert.type == 'confirm';
    }

    isPrompt() {
        return this.alert && this.alert.type == 'prompt';
    }

    isPromptDate() {
        return this.alert && this.alert.type == 'prompt_date';
    }

    isPromptNumber() {
        return this.alert && this.alert.type == 'prompt_number';
    }

    isPromptSelect() {
        return this.alert && this.alert.type == 'prompt_select';
    }

    isToast() {
        return this.alert && this.alert.type == 'toast';
    }

    isActionSheet() {
        return this.alert && this.alert.type == 'actionsheet';
    }

    isTextArea() {
        return this.alert && this.alert.type == 'textarea';
    }

    buttonClicked(callback) {
        this.promptValue = '';

        callback();

        this.clear();
    }

    buttonPromptConfirmClicked(callback) {
        if (!isNil(this.promptValue)) {
            callback(this.promptValue);
        }

        this.promptValue = '';

        this.clear();
    }

    buttonTextAreaConfirmClicked(callback) {
        callback(this.alert.textAreas);

        this.clear();
    }

    actionButtonClicked(button) {
        button.callback();


        this.clear();
    }

    clear() {
        this.promptValue = '';
        this.alert = null;
    }

    close($event) {
        if ($event.target.classList.contains('alert')) {
            if (this.isActionSheet()) {
                this.actionButtonClicked(this.alert.cancelButton);
            }

            this.clear();
        }
    }

    closeBackdrop($event) {
        if (this.alert.type === 'prompt' && !this.alert.clickOutsideToDismiss) {
            return;
        }

        if ($event.target.classList.contains('alert')) {
            if (this.isActionSheet()) {
                this.actionButtonClicked(this.alert.cancelButton);
            }

            this.clear();
        }
    }

    incrementNumber(value) {
        if (this.promptValue + value >= this.alert.minValue && this.promptValue + value <= this.alert.maxValue) {
            this.promptValue = this.promptValue + value;
        }
    }
}

export default AlertController
