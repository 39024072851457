import summaryComponent from './summary/summary.module';
import calendarComponent from './calendar/calendar.module';
import clubComponent from './club/club.module';
import profileComponent from './profile/profile.module';

export default angular
    .module('app.components', [
        summaryComponent,
        calendarComponent,
        clubComponent,
        profileComponent,
    ])
    .name;
