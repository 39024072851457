import controller from './preferences.component';
import template from './preferences.html';

export default angular
    .module('app.components.calendar.restaurants.preferences', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar.restaurants.preferences', {
                url: '/preferences',
                views: {
                    '@auth': 'preferences'
                },
                resolve: {
                    slotsResolver: (Http) => {
                        "ngInject";

                        return Http.get('/restaurant/config');
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('preferences', {
        bindings: {
            slotsResolver: '<',
        },
        template,
        controller,
    })
    .name;
