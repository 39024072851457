import { split, join, dropRight, pullAt } from 'lodash-es';

class NavigationService {
    constructor(Config, $state) {
        "ngInject";

        this.Config = Config;

        this.$state = $state;

        this.history = [];

        this.reset();
    }

    reset() {
        this.title = this.Config.title;
    }

    getTitle() {
        return this.title;
    }

    setTitle(title) {
        this.title = title;
    }

    push(state, params) {
        this.history.push({ state, params });
    }

    pull() {
        return pullAt(this.history, this.history.length - 1)[0];
    }

    flush() {
        this.history = [];
    }

    hasBackButton() {
        let nestedRoutes = split(this.$state.current.name, '.');

        return this.history.length > 0 || (nestedRoutes.length > 2 && !this.$state.current.data.excluded);
    }

    goBack() {
        let prev = this.pull(this.history);

        if (!prev) {
            this.$state.go(join(dropRight(split(this.$state.current.name, '.')), '.'), {}, { reload: true, inherit: false, custom: { stealth: true } });

            return;
        }

        this.$state.go(prev.state.name, prev.params, { reload: true, inherit: false, custom: { stealth: true } });
    }
}

export default angular
    .module('app.services.navigation', [])
    .service('Navigation', NavigationService)
    .name;
