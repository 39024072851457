class UpdaterService {
    constructor(Http, $timeout, $interval, Config) {
        "ngInject";

        this.Http = Http;

        this.$timeout = $timeout;
        this.$interval = $interval;

        this.Config = Config;

        this.updateAvailable = false;
    }

    init(frequency = 1000 * 60 * 15) {
        this.$timeout(() => {
            this.checkVersion();
        });

        this.$interval(() => {
            this.checkVersion();
        }, frequency);
    }

    checkVersion() {
        this.getRemoteHash().then((hash) => {
            this.updateAvailable = !(this.getHash() === hash);

            if (this.updateAvailable) {
                console.log(`New version available! Current: ${this.getHash()}/ New: ${hash}`);
            }
        });
    }

    isUpdateAvailable() {
        return this.updateAvailable;
    }

    getRemoteHash() {
        return this.Http.get(`${this.Config.appUrl}/updater.json?t=${new Date().getTime()}`).then((response) => {
            return response.data.hash;
        });
    }

    getHash() {
        return __webpack_hash__;
    }
}

export default angular
    .module('app.services.updater', [])
    .service('Updater', UpdaterService)
    .name;
