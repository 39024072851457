import { defaultTo, truncate } from 'lodash-es';

class ClubController {
    constructor(User, Navigation, Config, Alert, Calendar, $state, $stateParams, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Config = Config;
        this.Alert = Alert;
        this.Calendar = Calendar;

        this.$state = $state;
        this.$stateParams = $stateParams;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');

        this.tabs = [
            {
                name: this.$translate('CLUB_TAB_ACTIVITIES'),
                slug: 'activities',
            },
            {
                name: this.$translate('CLUB_TAB_POSTS'),
                slug: 'posts',
            },
            {
                name: this.$translate('CLUB_TAB_CATEGORY'),
                slug: 'category',
            }
        ];

        this.tab = this.tabs[0];

        this.tab = this.tabs.find((tab) => {
            return tab.slug === defaultTo(this.$stateParams.tab, 'activities');
        });
    }

    $onInit() {
        if (this.tab.slug === 'posts') {
            this.Navigation.setTitle(this.$translate('TAB_BAR_CLUB_FULL'));
        }

        if (this.tab.slug === 'activities') {
            this.Navigation.setTitle(this.$translate('TAB_BAR_ACTIVITIES'));
        }

        this.date = this.$stateParams.date ? moment(this.$stateParams.date) : null;

        let currentDate = moment();

        if (currentDate > moment(this.User.getUser().arrivalAt) && this.date == null) {
            this.date = currentDate;
        }
        else if (currentDate < moment(this.User.getUser().arrivalAt) && this.date == null) {
            this.date = moment(this.User.getUser().arrivalAt);
        }

        if (this.activitiesResolver) {
            this.activitiesResolver.then((data) => {
                this.activities = data.slots;
            });
        }

        if (this.postsResolver) {
            this.postsResolver.then((posts) => {
                this.posts = posts;

                if (this.tab.slug === 'category') {
                    this.Navigation.setTitle(this.posts.name);
                }
            });
        }
    }

    isDefaultCategory(category) {
        return !category.name && !category.imageName;
    }

    goToCategory(category, key) {
        if (this.isDefaultCategory(category)) {
            return;
        }

        this.$state.go('auth.club.category', { category: key }, { reload: true, inherit: false });
    }

    getEntries() {
        switch(this.tab.slug) {
            case 'posts':
                return this.posts;
            case 'category':
                return this.posts ? this.posts.posts : [];
            case 'activities':
                return this.activities;
            default:
                return this.posts;
        }
    }

    isImage(fileName) {
        return [
            '.jpg',
            '.jpeg',
            '.png',
            '.svg',
            '.gif',
        ].some((ext) => {
            return fileName.indexOf(ext) !== -1;
        });
    }

    getFile(entry) {
        if (!entry.imageName) {
            return null;
        }

        return `${this.Config.static_url}/${this.Config.upload_path}/${entry.imageName}`;
    }

    getPicto(entry) {
        return `/images/pictos/${entry.picto}`;
    }

    getHours(entry) {
        return this.$translate('FROM_TO', {
            start: entry.slotableConfig.startAtTime,
            end: entry.slotableConfig.endAtTime,
        });
    }

    getAvailableSeats(entry) {
        return this.$translate('CALENDAR_BOOKING_SLOT_SEATS_AVAILABLE', { seats: entry.seatsAvailable });
    }

    getTitle(entry) {
        switch(this.tab.slug) {
            case 'activities':
                return entry.name;
            case 'posts':
            default:
                return entry.title;
        }
    }

    isOpenRegistration(entry) {
        return entry.slotableConfig.maxPlaces === 0;
    }

    getCurrentDate() {
        return this.formatDate(this.date, 'localized');
    }

    goToDay(method, day) {
        let date = this.date[method](day, 'day');

        this.$state.go('auth.activities', { tab: this.tab.slug, date: this.formatDate(date, 'sql') }, { reload: true, inherit: false });
    }

    getContent(entry) {
        switch(this.tab.slug) {
            case 'activities':
                return entry.description;
            case 'posts':
            default:
                return entry.content;
        }
    }

    getExcerpt(entry) {
        let excerpt = this.getContent(entry);

        if (!excerpt) {
            return null;
        }

        return truncate(excerpt, {
            length: 280,
            separator: /\. /,
            omission: '...',
        });
    }

    hasReadMore(entry) {
        return this.getContent(entry).length > 280;
    }

    getAction(entry) {
        switch(this.tab.slug) {
            case 'activities':
                if (entry.slotableConfig.maxPlaces === 0) {
                    if (entry.reserved) {
                        return this.$translate('CLUB_ACTION_ACTIVITY_DONE');
                    } else {
                        return this.$translate('CLUB_ACTION_ACTIVITY_PARTICIPATE');
                    }
                }

                if (entry.seatsAvailable <= 0) {
                    return this.$translate('CLUB_ACTION_ACTIVITY_FULL');
                }

                if (entry.reserved) {
                    return this.$translate('CLUB_ACTION_ACTIVITY_DONE');
                } else {
                    return this.$translate('CLUB_ACTION_ACTIVITY');
                }
            case 'posts':
            default:
                return this.$translate('CLUB_ACTION_POST');
        }
    }

    isDisabled(entry) {
        if (entry.slotableConfig.maxPlaces !== 0 && entry.seatsAvailable <= 0) {
            return true;
        }

        return false;
    }

    goToEntry(entry) {
        this.$state.go('auth.club.entry', { slug: this.tab.slug, id: entry.id }, { reload: true, inherit: false });
    }

    buttonAction(slot) {
        if (!slot.reserved) {
            this.showPrompt(slot);
        } else {
            this.showConfirmDelete(slot);
        }
    }

    showPrompt(slot) {
        this.Alert.showPromptNumber({
            title: this.$translate('CALENDAR_SLOT_BOOK'),
            text: this.$translate('CALENDAR_SLOT_BOOK_PERSON_NUMBER'),
            defaultValue: this.User.getUser().personNumber,
            maxValue: this.User.getUser().personNumber,
            button: {
                label: this.$translate('VALIDATE'),
                classes: ['green', 'bold'],
            },
        }).then((seats) => {
            this.Calendar.postSlot(slot.id, seats).then(() => {
                slot.reserved = true;
                slot.reservedSeats = seats;
                slot.seatsAvailable -= seats;
            }).catch(() => {
                this.Alert.showToast({
                    text: this.$translate('CALENDAR_BOOKING_ERROR'),
                    duration: 1500,
                })
            })
        });
    }

    showConfirmDelete(slot) {
        this.Alert.showConfirm({
            title: this.$translate('CALENDAR_SLOT_DELETE_BOOKING'),
            text: this.$translate('CALENDAR_SLOT_DELETE_BOOKING_MESSAGE'),
            button: {
                label: this.$translate('VALIDATE'),
                classes: ['green', 'bold'],
            },
        }).then(() => {
            this.Calendar.deleteSlot(slot.id).then(() => {
                slot.reserved = false;
                console.log(slot);

                if (slot.reservedSeats) {
                    slot.seatsAvailable += slot.reservedSeats;
                }
            }).catch(() => {
                this.Alert.showToast({
                    text: this.$translate('CALENDAR_BOOKING_ERROR'),
                    duration: 1500,
                })
            })
        })
    }
}

export default ClubController;
