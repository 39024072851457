import controller from './alert.component';
import template from './alert.html';
import alertService from './alert.service';

export default angular
    .module('app.core.alert', [])
    .service('Alert', alertService)
    .component('alert', {
        template,
        controller,
    })
    .name;
