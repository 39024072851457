import { truncate } from "lodash-es";

class CalendarController {
    constructor(User, Navigation, Alert, $state, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Alert = Alert;

        this.$state = $state;

        this.$translate = $filter('translate');

        this.restaurants = {};
        this.places = {};
        this.kids = {};
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('TAB_BAR_CALENDAR_FULL'));

        this.placesResolver.then((places) => {
            this.restaurants = places.find((row) => {
                if (row.type === 'RESTAURANT') {
                    return row.items;
                }
            });

            this.places = places.find((row) => {
                if (row.type === 'PLACE') {
                    return row.items;
                }
            });

            this.kids = places.find((row) => {
                if (row.type === 'KID') {
                    return row.items;
                }
            });
        });
    }

    isRestaurantBooked() {
        return this.User.getUser().restaurantBooked;
    }

    hasRestaurantDefault() {
        return this.restaurants.items && this.restaurants.items.find((item) => {
            return item.default;
        });
    }

    getRestaurantDefaultId() {
        return this.restaurants.items && this.restaurants.items.find((item) => {
            return item.default;
        }).id
    }

    getSpecialRestaurants() {
        if (!this.restaurants.items) {
            return null;
        }

        return this.restaurants.items.filter((item) => {
            return !item.default;
        })
    }

    getChildren() {
        if (!this.kids.items) {
            return [];
        }

        let children = this.kids.items.map((item) => {
            return item.children.map((child) => {
                child.clubData = item.clubData;
                child.slotable = item.slotable;

                return child;
            });
        });

        children = children.flat();

        return children;
    }

    isUserHalfOrFullBoard() {
        return this.User.getUser().mode !== 1;
    }

    truncate(text) {
        return truncate(text, {
            length: 140,
            separator: /\. /,
            omission: '.',
        });
    }
}

export default CalendarController;
