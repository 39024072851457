import controller from './login.component';
import template from './login.html';

export default angular
    .module('app.core.login', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('login', {
                url: '/login',
                component: 'login',
            });
    })
    .component('login', {
        bindings: {},
        template,
        controller,
    })
    .name;
