import controller from './onboarding.component';
import template from './onboarding.html';
import service from './onboarding.service';

export default angular
    .module('app.core.modals.onboarding', [])
    .service('Onboarding', service)
    .component('onboarding', {
        bindings: {},
        template,
        controller,
    })
    .name;
