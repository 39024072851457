import { defaultTo } from 'lodash-es';

class EntryController {
    constructor(User, Navigation, Config, $state, $stateParams, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Config = Config;

        this.$state = $state;
        this.$stateParams = $stateParams;

        this.$translate = $filter('translate');

        this.slug = defaultTo(this.$stateParams.slug, 'activities');
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('TAB_BAR_CLUB'));

        this.entryResolver.then((entry) => {
            this.entry = entry;

            this.Navigation.setTitle(this.getTitle());
        });
    }

    isSlug(slug) {
        return slug === this.slug;
    }

    isImage(fileName) {
        return [
            '.jpg',
            '.jpeg',
            '.png',
            '.svg',
            '.gif',
        ].some((ext) => {
           return fileName.indexOf(ext) !== -1;
        });
    }

    getFile() {
        if (!this.entry.imageName) {
            return null;
        }

        return `${this.Config.static_url}/${this.Config.upload_path}/${this.entry.imageName}`;
    }

    getSecondaryFile() {
        if (!this.entry.secondaryImageName) {
            return null;
        }

        return `${this.Config.static_url}/${this.Config.upload_path}/${this.entry.secondaryImageName}`;
    }

    getTitle() {
        switch(this.slug) {
            case 'activities':
            case 'RESTAURANT':
            case 'PLACE':
            case 'KID':
                return this.entry.name;
            case 'posts':
            default:
                return this.entry.title;
        }
    }

    getContent() {
        let content = null;

        switch(this.slug) {
            case 'activities':
            case 'RESTAURANT':
            case 'PLACE':
            case 'KID':
                content = this.entry.description;
                break;
            case 'posts':
            default:
                content = this.entry.content;
        }

        if (!content) {
            return null;
        }

        return content.replace(/\n/g, '<br>');
    }

    getSecondaryContent() {
        let content = null;

        switch(this.slug) {
            case 'activities':
            case 'RESTAURANT':
            case 'PLACE':
            case 'KID':
                content = this.entry.secondaryDescription;
                break;
            case 'posts':
            default:
                content = null;
        }

        if (!content) {
            return null;
        }

        return content.replace(/\n/g, '<br>');
    }
}

export default EntryController;
