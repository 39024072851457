import httpService from './http/http.service';
import userService from './user/user.service';
import navigationService from './navigation/navigation.service';
import configService from './config/config.service';
import updaterService from './updater/updater.service';
import viewportService from './viewport/viewport.service';
import clubService from './club/club.service';
import kidsService from './kids/kids.service';
import calendarService from './calendar/calendar.service';

export default angular
    .module('app.services', [
        httpService,
        userService,
        navigationService,
        configService,
        updaterService,
        viewportService,
        clubService,
        kidsService,
        calendarService,
    ])
    .name;
