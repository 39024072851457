import controller from './profile-form.component';
import template from './profile-form.html';

export default angular
    .module('app.components.profile.form', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.profile.form', {
                url: '/form',
                data: {
                    flush: true,
                    excluded: true,
                },
                views: {
                    '@auth': 'profileForm'
                }
            })
            .state('auth.profile.form-edit', {
                url: '/form/edit',
                data: {
                    flush: true,
                    excluded: true,
                },
                resolve: {
                    edit: () => {
                        return true;
                    },
                },
                views: {
                    '@auth': 'profileForm'
                }
            });
    })
    .component('profileForm', {
        bindings: {
            edit: '<',
        },
        template,
        controller,
    })
    .name;
