import cacheComponent from './cache/cache.module';
import mainComponent from "./main/main.module";
import loginComponent from "./login/login.module";
import alertComponent from "./alert/alert.module";
import loaderComponent from "./loader/loader.module";
import authComponent from "./auth/auth.module";
import modalComponent from "./modal/modal.module";
import modalsComponent from "./modals/modals.module";

export default angular
    .module('app.core', [
        cacheComponent,
        alertComponent,
        loaderComponent,
        mainComponent,
        loginComponent,
        authComponent,
        modalComponent,
        modalsComponent,
    ])
    .name;
