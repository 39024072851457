export default class OnboardingService {
    constructor(Modal, $q) {
        "ngInject";

        this.Modal = Modal;

        this.$q = $q;

        this.reset();
    }

    reset() {
        this.deferred = null;
    }

    show(options = {}) {
        this.reset();

        this.deferred = this.$q.defer();

        this.Modal.open('onboarding');

        return this.deferred.promise;
    }

    confirm() {
        this.deferred.resolve();

        this.reset();

        this.Modal.close('onboarding');
    }

    cancel() {
        if (this.deferred) {
            this.deferred.reject();
        }

        this.reset();

        this.Modal.close('onboarding');
    }
}
