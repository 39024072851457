class SummaryController {
    constructor(User, Navigation, Calendar, Pass, Alert, $filter, $state) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Calendar = Calendar;
        this.Pass = Pass;
        this.Alert = Alert;

        this.$state = $state;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');

        this.userSlotsByDay = null;
        this.now = moment();
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('TAB_BAR_SUMMARY'));

        this.slotsResolver.then((slots) => {
            this.initSummaryData(slots);
            this.expandDay(this.userSlotsByDay[0]);
        });
    }

    initSummaryData(slots) {
        this.userSlotsByDay = slots.userSlots.sort((a,b) => a.date > b.date ? 1 : -1);

        this.userSlotsByDay = this.userSlotsByDay.map((userSlotsForDay, index) => {
            userSlotsForDay.userSlots = this.sortByDate(userSlotsForDay.userSlots);
            userSlotsForDay.expanded = (index === 0);

            return userSlotsForDay;
        });
    }

    expandDay(day) {
        this.userSlotsByDay.forEach((userSlotsForDay) => {
            userSlotsForDay.expanded = (day.date === userSlotsForDay.date);
        });
    }

    sortByDate(arr) {
        return arr.sort((a,b) => a.slot.startAt > b.slot.startAt ? 1 : -1);
    }

    subtractTenMinutes(date) {
        return moment(date).subtract(10, 'minutes').format();
    }

    isChildClub(userSlot) {
        return [
            'CHICKS',
            'FOXES',
            'PIRATES',
            'JUNIORS',
            'TEENS',
        ].indexOf(this.getType(userSlot)) !== -1;
    }

    getType(userSlot) {
        return userSlot.slot.slotableConfig.slotable.type;
    }

    isDefaultRestaurant(userSlot) {
        return userSlot.slot.slotableConfig.slotable.default;
    }

    getClassType(userSlot) {
        if (this.getType(userSlot) === 'RESTAURANT' && !this.isDefaultRestaurant(userSlot)) {
            return 'restaurant_special';
        }

        return this.getType(userSlot).toLowerCase();
    }

    getName(userSlot) {
        switch(this.getType(userSlot)) {
            case 'ACTIVITY':
                return userSlot.slot.name;
            case 'RESTAURANT':
                return this.$translate(this.Calendar.getMeals().find((meal) => {
                    return userSlot.slot.slotableConfig.type === meal.type;
                }).name);
            default:
                return userSlot.slot.slotableConfig.slotable.name;
        }
    }

    getRestaurantName(userSlot) {
        return userSlot.slot.slotableConfig.slotable.name;
    }

    getSeats(userSlot) {
        if (this.isChildClub(userSlot)) {
            return userSlot.child.form.groups[0].fields[4].value;
        }

        return `${userSlot.seats} ${this.$translate('PERSONS')}`;
    }

    getPicto(userSlot) {
        return `/images/pictos/${userSlot.slot.picto}`;
    }

    getHours(userSlot) {
        return this.$translate('FROM_TO', {
            start: userSlot.slot.slotableConfig.startAtTime,
            end: userSlot.slot.slotableConfig.endAtTime,
        });
    }

    action(userSlot) {
        let slotable = userSlot.slot.slotableConfig.slotable;
        let slotableConfig = userSlot.slot.slotableConfig;
        let slot = userSlot.slot;

        if (slotable.type === 'RESTAURANT' && slotable.default) {
            this.$state.go('auth.calendar.restaurants.booking', {
                date: this.formatDate(slot.startAt, 'sql'),
                type: slotableConfig.type
            }, { reload: true, inherit: false });
        }
        else if ((slotable.type === 'RESTAURANT' && !slotable.default) || slotable.type === 'PLACE') {
            this.$state.go('auth.calendar.slot', {
                id: slotable.id,
            }, { reload: true, inherit: false });
        }
        else {
            this.$state.go('auth.activities', {
                tab: 'activities',
                date: this.formatDate(slot.startAt, 'sql'),
            }, { reload: true, inherit: false });
        }

        // this.Alert.showConfirm({
        //     title: this.$translate('CALENDAR_SLOT_DELETE_BOOKING'),
        //     text: this.$translate('CALENDAR_SLOT_DELETE_BOOKING_MESSAGE'),
        //     button: {
        //         label: this.$translate('VALIDATE'),
        //         classes: ['green', 'bold'],
        //     },
        // }).then(() => {
        //     let expandedDay = this.userSlotsByDay.find((day) => {
        //         return day.expanded;
        //     });
        //
        //     this.Calendar.deleteSlot(slot.id, userSlot.child ? userSlot.child.id: null).then(() => {
        //         slot.reserved = false;
        //
        //         this.User.resetCache();
        //
        //         this.Calendar.getUserSlots().then((data) => {
        //             this.initSummaryData(data);
        //
        //             this.expandDay(this.userSlotsByDay.find((day) => {
        //                 return day.date === expandedDay.date;
        //             }));
        //         })
        //     }).catch(() => {
        //         this.Alert.showToast({
        //             text: this.$translate('CALENDAR_BOOKING_ERROR'),
        //             duration: 1500,
        //         })
        //     });
        // });
    }
}

export default SummaryController;
