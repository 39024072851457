import { cloneDeep, isArray, get } from 'lodash-es';

class RegisterController {
    constructor(User, Navigation, Kids, Calendar, $templateCache, $filter, $state, $stateParams) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Kids = Kids;
        this.Calendar = Calendar;

        this.$templateCache = $templateCache;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $filter('translate');

        this.moment = moment;

        this.$templateCache.put('fields_type_checkbox', require('./fields/checkbox.html'));
        this.$templateCache.put('fields_type_date', require('./fields/date.html'));
        this.$templateCache.put('fields_type_longtext', require('./fields/longtext.html'));
        this.$templateCache.put('fields_type_radio', require('./fields/radio.html'));
        this.$templateCache.put('fields_type_repeatable', require('./fields/repeatable.html'));
        this.$templateCache.put('fields_type_text', require('./fields/text.html'));
        this.$templateCache.put('fields_type_title', require('./fields/title.html'));

        this.form = null;
        this.data = null;
        this.clientDetail = null;
        this.readOnly = false;
    }

    $onInit() {
        this.dataResolver.then((response) => {
            let data = response.data;
            this.data = data;

            this.form = cloneDeep(data.form);

            this.form.groups.forEach((group) => {
                group.fields.forEach((field) => {
                    switch(field.name) {
                        case 'birthDate':
                            if (!this.$stateParams.birthdate) {
                                break;
                            }

                            field.value = moment(this.$stateParams.birthdate);
                            field.readOnly = true;

                            break;
                        case 'firstName':
                            if (!this.$stateParams.firstname) {
                                break;
                            }

                            field.value = this.$stateParams.firstname;
                            field.readOnly = true;

                            break;
                        case 'lastName':
                            if (!this.$stateParams.name) {
                                break;
                            }

                            field.value = this.$stateParams.name;
                            field.readOnly = true;

                            break;
                    }
                });
            });

            let title;

            if (data.name !== undefined) {
                title = data.name;
            }

            let firstName = data.form.groups[0].fields.find((field) => {
                return field.name === 'firstName';
            }).value;

            if (!!firstName) {
                title = firstName;
            }

            this.Navigation.setTitle(title);

            if (response.clientDetail === undefined) {
                this.readOnly = true;
            } else {
                this.clientDetail = response.clientDetail;
            }
        });
    }

    arrInit(object, key, item) {
        if (!isArray(object[key])) {
            object[key] = [];
        }

        this.arrPush(object[key], item);
    }

    arrPush(array, item) {
        return array.push(cloneDeep(item));
    }

    arrPull(array, index) {
        return array.splice(index, 1);
    }

    isSubmittable() {
        if (!this.form) {
            return false;
        }

        return this.form.groups.every((group) => {
            return group.fields.every((field) => {
                return this.isValid(field);
            });
        });
    }

    isValid(field) {
        switch(field.type) {
            case 'text':
            case 'date':
            case 'radio':
               return !!field.value;
            case 'checkbox':
                if (!field.value) {
                    return false;
                }

                return Object.values(field.value).some((choice) => {
                    return choice;
                });
            case 'repeatable':
                if (!field.value) {
                    return false;
                }

                return field.value.every((repeatable) => {
                    return repeatable.every((item) => {
                        return this.isValid(item);
                    });
                });
            case 'longtext':
            default:
                return true;
        }
    }

    submit() {
        this.Kids.postKidForm(this.data.id, this.form).then(() => {
            this.Calendar.resetCache();
            this.$state.go('auth.calendar', {}, { reload: true, inherit: false });
        });
    }

    getFieldTemplate(type) {
        return `fields_type_${type}`;
    }
}

export default RegisterController;
