let translations = {
    "VALIDATE": "Valider",
    "BOOK_NOW": "Je réserve",
    "BOOK_MANAGE": "Gérer mes reservations",
    "LOGOUT": "Déconnexion",
    "DELETE": "Supprimer",
    "EDIT": "Modifier",
    "COMPLETE": "Compléter",
    "DETAILS": "Détails",
    "ADD": "Ajouter",
    "PERSONS": "pers.",
    "FULL_PERSONS": "personne(s)",
    "FROM_TO": "De {{ start }} à {{ end }}",
    "ALERT_CANCEL": "Annuler",
    "ALERT_CLOSE": "Fermer",
    "UPDATER_UPDATE_AVAILABLE": "Une mise à jour est disponible pour {{ platformName }}",
    "UPDATER_RELOAD_PAGE": "Recharger la page",
    "LOADER_LOADING": "Chargement...",
    "NEXT_STEP": "Étape suivante",
    "TOP_BAR_BACK": "Retour",
    "TAB_BAR_SUMMARY": "Mon séjour",
    "TAB_BAR_CALENDAR": "Réservations",
    "TAB_BAR_CALENDAR_FULL": "Mes Réservations",
    "TAB_BAR_CLUB": "Informations",
    "TAB_BAR_CLUB_FULL": "Informations pratiques",
    "TAB_BAR_ACTIVITIES": "Activités",
    "TAB_BAR_PROFILE": "Mon Profil",
    "LOGIN_TITLE": "Bienvenue",
    "LOGIN_SUBTITLE": "Préparez votre séjour en toute sérénité. Et profitez ainsi de vos vacances.",
    "LOGIN_USERNAME_PLACEHOLDER": "Votre nom",
    "LOGIN_PASSWORD_PLACEHOLDER": "Votre numéro de dossier",
    "LOGIN_SUBMIT_BUTTON_LABEL": "Connexion",
    "LOGIN_WRONG_CREDENTIALS": "Nom ou numéro de dossier incorrect !",
    "PROFILE_PLAN_RENTAL": "Location",
    "PROFILE_PLAN_HALF_BOARD": "Demi-pension",
    "PROFILE_PLAN_FULL_BOARD": "Pension complète",
    "PROFILE_PERSON_NAME": "Nom",
    "PROFILE_PERSON_EMAIL": "Email",
    "PROFILE_ARRIVAL_DATE": "Date d'arrivée",
    "PROFILE_LEAVE_DATE": "Date de départ",
    "PROFILE_PERSON_NUMBER": "Nombre d'adultes et d'enfants (2 ans et +)",
    "PROFILE_PERSON_NUMBER_DETAILS": "{{ adults }} adulte(s), {{ children }} enfant(s)",
    "PROFILE_PLAN": "Mon séjour",
    "PROFILE_CLUB": "Mon club",
    "PROFILE_FORM_TITLE": "Compléter mon profil",
    "PROFILE_EXPLANATIONS": "Avant de commencer à réserver vos repas, activités et clubs enfants, veuillez confirmer ces informations à propos de votre séjour :",
    "PROFILE_FORM_EDIT_TITLE": "Editer mon profil",
    "PROFILE_FORM_EDIT_EXPLANATIONS": "Editer mon profil",
    "CALENDAR_RESTAURANTS_TITLE": "Restauration",
    "CALENDAR_RESTAURANTS_TEXT": "Réservez dès maintenant vos horaires pour toute la durée de votre séjour.",
    "CALENDAR_ACTIVITIES_TITLE": "Activités",
    "CALENDAR_ACTIVITIES_TEXT": "Réservez vos horaires de piscine, sauna, et autres activités...",
    "CALENDAR_KIDS_TITLE": "Clubs enfants",
    "CALENDAR_KIDS_TEXT": "Inscrivez vos enfants dès maintenant aux clubs de Léo !",
    "CALENDAR_RESTAURANTS_BREAKFAST": "Petit-déjeuner",
    "CALENDAR_RESTAURANTS_LUNCH": "Déjeuner",
    "CALENDAR_RESTAURANTS_DINNER": "Dîner",
    "CALENDAR_RESTAURANTS_NO_BOOKING": "<p>Vous n'avez aucune réservation pour le moment.</p><p>Afin de réserver des créneaux vous devez choisir vos préférences en cliquant sur le bouton ci-dessous :</p>",
    "CALENDAR_RESTAURANTS_NO_BOOKING_ACTION": "Choisir vos préférences",
    "CALENDAR_RESTAURANTS_PREFERENCES_INTRO": "<p>Grâce à vos préférences nous allons pré-réserver des créneaux correspondants pour toute la durée de votre séjour. Vous pourrez ensuite modifier chaque réservation individuellement.</p>",
    "CALENDAR_RESTAURANTS_PREFERENCES_HALF_BOARD": "Demi-pension",
    "YES": "Oui",
    "NO": "Non",
    "FORM_ARRIVAL_DATE": "Date d'arrivée",
    "FORM_LEAVE_DATE": "Date de départ",
    "FORM_GENERAL_INFORMATIONS": "Informations générales",
    "FORM_ROOM": "N° de logement",
    "FORM_BIRTHDATE": "Date de naissance de l'enfant",
    "FORM_FIRSTNAME": "Prénom de l'enfant",
    "FORM_LASTNAME": "Nom de l'enfant",
    "FORM_AUTHORIZED_PEOPLE": "Liste des personnes autorisées à récupérer l'enfant",
    "FORM_AUTHORIZED_PEOPLE_ACTION": "Ajouter une personne",
    "FORM_PERSON_NAME": "Nom de la personne autorisée à récupérer l'enfant",
    "FORM_RELATIONSHIP": "Lien de parenté",
    "FORM_PHONE_NUMBER": "Numéro de téléphone",
    "FORM_MEALS": "Repas",
    "FORM_MEAL_FORMULA": "Formule repas",
    "FORM_HALF_BOARD": "Demi-Pension",
    "FORM_FULL_BOARD": "Pension complète",
    "FORM_LOCATION": "Location",
    "FORM_DIETARY_RECOMMENDATIONS": "Consignes alimentaires",
    "FORM_FOOD_ALLERGIES_INTOLERANCES": "Allergies ou intolérances alimentaires",
    "FORM_NAP": "Sieste",
    "FORM_PACIFIER_EXPLANATIONS": "Description doudou / tétine",
    "FORM_POUSSINS_USUALMODE": "Mode de garde habituel",
    "FORM_POUSSINS_FULLTIME": "Temps plein",
    "FORM_POUSSINS_OCCASIONAL": "Ponctuel",
    "FORM_POUSSINS_INITIAL_SEPARATION": "Première séparation",
    "FORM_HEALTH": "Santé",
    "FORM_VACCINATIONS": "Vaccinations : chaque accompagnant doit pouvoir justifier de la mise à jour des vaccins réglementaires (carnet de santé ou tout autre document d'un professionnel de santé attestant de la situation au regard des vaccinations obligatoires).",
    "FORM_IF_CHILD_HAS_TREATMENT": "Si votre enfant suit un traitement médical, merci de vous rapprocher du personnel du Club pour nous transmettre les informations",
    "FORM_ADDITIONAL_COMMENT": "Autres remarques (allergies, contre-indications, lunettes…)",
    "FORM_PARENTAL_AUTHORIZATION": "Autorisation parentale",
    "FORM_PHOTO_PERMISSION": "Autorisation de prise de photo (activités et/ou diaporama de fin de semaine non publicitaire)",
    "FORM_AUTHORISATION_TO_LEAVE": "Autorisation de sortie",
    "FORM_AUTHORISE_MY_CHILD_TO_LEAVE_ALONE": "J'autorise mon enfant à quitter seul le club des pirates à la fin des activités",
    "FORM_AUTHORISE_MY_CHILD_TO_LEAVE_ALONE_DESCRIPTION": "BELAMBRA ne pourra être tenu responsable de sa surveillance qui incombe exclusivement aux parents dès sa sortie du club enfants et/ou en dehors des activités organisées spécifiquement par le club",
    "FORM_POUSSINS_FOOD": "Alimentation",
    "FORM_POUSSINS_BABY_BOTTLE": "Biberon",
    "FORM_POUSSINS_SMALL_POTS": "Petits pots",
    "FORM_POUSSINS_BLENDED_FOOD": "Aliments mixés",
    "FORM_POUSSINS_CHOPPED_FOOD": "Aliments coupés en morceaux",
    "FORM_POUSSINS_DRINK": "Boisson",
    "FORM_POUSSINS_KETTLE_DRUM": "Timbale à bec",
    "FORM_POUSSINS_CHOPPED_GLASS": "Verre",
    "FORM_POUSSINS_BABY_BOTTLE_TEMPERATURE": "Température biberon",
    "FORM_POUSSINS_BABY_AMBIENT": "Ambiante",
    "FORM_POUSSINS_HOT": "Chauffée",
    "FORM_POUSSINS_MORNING_EXPLANATIONS": "Si matin",
    "FORM_POUSSINS_AFTERNOON_EXPLANATIONS": "Si après-midi",
    "FORM_POUSSINS_OBJECTS": "Objets",
    "FORM_POUSSINS_DOUDOU": "Doudou",
    "FORM_POUSSINS_PACIFIER": "Tétine",
    "FORM_POUSSINS_SLEEPING_BAG": "Gigoteuse",
    "FORM_POUSSINS_NIGHT_LIGHT": "Veilleuse",
    "FORM_POUSSINS_APPRENTICESHIP": "Apprentissages",
    "FORM_POUSSINS_CLEANINESS": "Propreté",
    "FORM_POUSSINS_ALL_DAY": "Couche toute la journée",
    "FORM_POUSSINS_ONLY_FOR_NAP": "Couche seulement pour la sieste",
    "FORM_POUSSINS_APPRENTICED": "En cours d'apprentissage",
    "FORM_POUSSINS_CLEAN": "Propre",
    "FORM_POUSSINS_FORM_NAME": "Fiche inscription Poussins (3 mois à – de 3 ans)",
    "FORM_FOXES_YES": "Oui (préciser combien de temps)",
    "FORM_FOXES_CHILD_CHOICE": "Au choix de l'enfant",
    "FORM_FOXES_NO": "Non",
    "FORM_FOXES_FORM_NAME": "Fiche inscription Renardeaux (3 ans à – de 6 ans)",
    "FORM_FOXES_NAPPY_FOR_NAP": "Couche pour la sieste ?",
    "FORM_FOXES_WAKE_UP": "Réveil",
    "FORM_FOXES_WAKE_UP_YES": "Oui, à 15h pour le début de l'activité",
    "FORM_FOXES_WAKE_UP_NO": "Non, je souhaite qu'on laisse mon enfant dormir",
    "FORM_PIRATES_FORM_NAME": "Fiche inscription Pirates (6 ans  à - de 11 ans)",
    "FORM_JUNIORS_FORM_NAME": "Fiche inscription club Juniors (11 ans à – de 14 ans)",
    "FORM_ADOS_FORM_NAME": "Fiche inscription club Ados (14 ans à 17 ans)",
    "CLUB_TAB_POSTS": "Infos pratiques",
    "CLUB_TAB_ACTIVITIES": "Animations",
    "CLUB_ACTION_ACTIVITY": "Je m'inscris",
    "CLUB_ACTION_ACTIVITY_PARTICIPATE": "Ajouter à mon programme",
    "CLUB_ACTION_ACTIVITY_DONE": "Je suis inscrit !",
    "CLUB_ACTION_ACTIVITY_FULL": "Complet",
    "CLUB_ACTION_POST": "Lire la suite",
    "CLUB_CHILD_SUBSCRIPTION": "Inscrire un enfant",
    "CALENDAR_BOOKING_TITLE": "Modifier ma réservation",
    "CALENDAR_BOOKING_DELETE_BOOKING": "Supprimer ma réservation",
    "CALENDAR_BOOKING_DELETE_GROUP": "Supprimer le groupe",
    "CALENDAR_BOOKING_ADD_GROUP": "Ajouter un groupe",
    "CALENDAR_BOOKING_SPLIT": "Manger séparément",
    "CALENDAR_BOOKING_GROUP_SEATS": "personne(s)",
    "CALENDAR_BOOKING_SLOT_SEATS": "{{ seats }} places",
    "CALENDAR_BOOKING_SLOT_SEATS_AVAILABLE": "{{ seats }} place(s) disponible(s)",
    "CALENDAR_BOOKING_ERROR": "Erreur",
    "CALENDAR_BOOKING_TOO_MANY_SEATS_MESSAGE": "Le nombre total de personnes ne peut pas être supérieur au nombre de personnes composant votre séjour",
    "CALENDAR_BOOKING_NOT_ENOUGH_SEATS_MESSAGE": "Le créneau sélectionné n'a pas assez de places disponibles",
    "CALENDAR_BOOKING_MINIMUM_SEATS_MESSAGE": "Le groupe ne peut pas être composé de moins d'une personne",
    "CALENDAR_BOOKING_DELETE_BOOKING_MESSAGE": "Êtes-vous sûr de vouloir supprimer votre réservation ?",
    "EDIT_PROFILE": "Editer mon profil",
    "SEE_CGU": "Voir les conditions d'utilisations",
    "MAX_SLOT_PER_DAY_REACHED": "Vous avez atteint le maximum de réservation autorisé sur une journée.",
    "NOT_ENOUGH_PLACES": "Il n'y a plus assez de places disponible.",
    "TOO_MANY_SEATS": "Vous avez demandé trop de places.",
    "CALENDAR_KIDS_DISCOVER_THIS_CLUB": "Découvrir ce club enfant",
    "CALENDAR_SLOT_BOOK": "Réserver",
    "CALENDAR_SLOT_BOOK_MESSAGE": "Voulez vous réserver ce créneau ?",
    "CALENDAR_SLOT_BOOK_PERSON_NUMBER": "Combien de personnes seront présentes ?",
    "CALENDAR_SLOT_DELETE_BOOKING": "Supprimer la réservation",
    "CALENDAR_SLOT_DELETE_BOOKING_MESSAGE": "Voulez vous supprimer cette réservation ?",
    "CALENDAR_DISCOVER_RESTAURANT": "Découvrir le restaurant",
    "CALENDAR_DISCOVER_PLACE": "Découvrir ce lieu",
    "CALENDAR_KIDS_REGISTERED": "Mes enfant(s) inscrit(s)",
    "CALENDAR_DISCOVER_KID_CLUBS": "Découvrir les clubs enfant",
    "SUMMARY_WELCOME": "Bonjour {{ name }},",
    "SUMMARY_OVERVIEW": "Nous sommes le {{ date }} et vous avez {{ total }} réservation(s) aujourd'hui !",
    "CLUB_ACTIVITY_DISCOVER": "Découvrir cette animation",
    "LOGIN_TERMS": "Conditions générales d'utilisation",
    "LOGIN_TERMS_CHECKBOX": "J'accepte les conditions générales d'utilisation",
    "LOGIN_TERMS_BUTTON": "Voir les CGU",
    "CLUB_ENTRY_DOWNLOAD_FILE": "Télécharger le document",
    "SUMMARY_NO_BOOKING_TITLE": "Vous n'avez pas encore de réservation !",
    "SUMMARY_NO_BOOKING_MESSAGE": "Parcourez l'application pour réserver un repas au restaurant, votre après-midi piscine, une animation ou encore pour inscrire vos enfants au club de Léo.",
    "SUMMARY_NO_BOOKING_ACTION_MANAGE": "Gérer mes réservations",
    "SUMMARY_NO_BOOKING_ACTION_DISCOVER": "Découvrir les activités",
    "CLUB_DETAIL_FOXES": "de 3 à 5 ans",
    "CLUB_DETAIL_PIRATES": "de 6 à 10 ans",
    "CLUB_DETAIL_CHICKS": "de 3 à 35 mois",
    "CLUB_DETAIL_JUNIORS": "de 11 à 13 ans",
    "CLUB_DETAIL_TEENS": "de 14 à 17 ans",
    "PROFILE_FORM_WARNING": "Si ces informations sont incorrectes, merci de vous rapprocher de la réception de votre club.",
    "PROFILE_FORM_TERMS": "La collecte des données précitées par Belambra a pour seules finalités de vous permettre d’utiliser l’application en créant notamment votre espace personnel dans lequel vous retrouverez les éléments de votre réservation ainsi que tout renseignement pratique concernant la destination de votre séjour ainsi que les services proposés par le club (carte bar, activités, accès aux clubs enfants …) vous permettant ainsi d’accéder à ces derniers.<br>Ces informations (adresse email) pourront aussi servir également pour informer sur les offres commerciales et promotions spéciales de Belambra, qui reste destinataire de ces données en tant que responsable du traitement. Belambra pourra le cas échéant avoir recours à des sous-traitants pour satisfaire les besoins des autres traitements précités. Dans une telle hypothèse, ces derniers n’accèderont à vos données que sur instruction de Belambra et dans le respect des dispositions légales en vigueur ainsi que des règles de sécurité définies par Belambra. Les données qui sont collectées lors de la connexion à l’Application, à savoir l’adresse email, auront une durée de conservation qui ne saurait en tout état de cause excéder 10 ans.<br>Nous vous rappelons que vous disposez d’un droit d’accès, rectification, effacement, limitation, portabilité, opposition, de définir le sort de vos données personnelles « post mortem » et réclamation pour le traitement des données vous concernant que vous pourrez exercer en vous adressant au « Délégué à la Protection des Données » de Belambra à cette adresse : deleguepotectiondesdonnees@belambra.fr",
    "PROFILE_FORM_EMAIL_OPTIN": "En cochant cette case, vous acceptez de recevoir des offres promotionnelles par email de la part de Belambra Clubs et attestez avoir pris connaissance de sa politique de traitement des données personnelles",
    "CLUB_ACTIVITIES_OPEN_REGISTRATION": "Participation libre",
    "SUMMARY_RESTAURANT_ARRIVAL_AT": "Merci d'arriver entre {{ start }} et {{ end }}",
    "NO_MORE_SEATS": "Il n'y a plus assez de places disponibles",
    "MODAL_ONBOARDING_TITLE": "Votre séjour l'esprit libre",
    "MODAL_PASS_TITLE": "Récapitulatif de votre réservation",
    "SHOW_PASS": "Afficher ma réservation",
    "FORM_AUTHORIZE_DIRECTOR": "Autorisation d'intervenir en cas d'urgence",
    "FORM_I_UNDERSIGNED": "Je soussigné",
    "FORM_PARENT_CHILD": "parent de l’enfant",
    "FORM_UNDERSIGNED_DIRECTOR_AUTHORIZATION": "autorise le Directeur du club Belambra ou son représentant, à prendre, en cas d’absence, toute disposition concernant les soins d’urgence à apporter à mon enfant, dans le cadre des clubs et, notamment, faire appel à un médecin ou à procéder à une hospitalisation. Etant entendu que tout sera fait pour me (nous) prévenir dans les plus bref délais",
    "FORM_ERROR_DATE_MISSING": "Vous devez entrer une date pour envoyer le formulaire.",
    "KIDS_NO_CLUB_FOR_AGE": "Désolé, aucun club de Léo ne correspond à l'age de votre enfant.",
    "KIDS_NO_CLUB_FOR_RENTAL": "Désolé, vous ne pouvez pas profiter du club de Léo pour les enfants de moins de 3 ans en étant en location.",
    "USER_OVERAGE_CONFIRM_TITLE": "Réservation non-incluse",
    "USER_OVERAGE_CONFIRM_TEXT": "Vous êtes sur le point d’effectuer une réservation qui n’est pas incluse dans votre formule. Cette réservation vaut acception d’une facturation supplémentaire de {{ total }}€ en fin de séjour. Pour ne pas engendrer de facturation supplémentaire, vous pouvez supprimer d’abord une réservation future avant d’effectuer cette nouvelle réservation.",
    "USER_OVERAGE_CONFIRM_ACTION": "J'accepte et je réserve",
    "CLUB_GO_TO_CATEGORY": "Accéder",
    "CLUB_TAB_CATEGORY": "Catégorie",
    "USER_OVERAGE_KID_CONFIRM_TEXT": "Vous êtes sur le point d’effectuer une réservation qui n’est pas incluse dans votre formule. Cette réservation vaut acception d’une facturation supplémentaire de {{ total }}€ en fin de séjour.",
    "USER_OVERAGE_ADULTS_COUNT_TITLE": "Nombre d'adultes",
    "USER_OVERAGE_CHILDREN_COUNT_TITLE": "Nombre d'enfants",
    "USER_OVERAGE_ADULTS_COUNT_TEXT": "Combien d'adultes pour cette réservation ?",
    "USER_OVERAGE_CHILDREN_COUNT_TEXT": "Combien d'enfants pour cette réservation ?",
};

module.exports = translations;
