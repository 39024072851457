import controller from './slot.component';
import template from './slot.html';

export default angular
    .module('app.components.calendar.slot', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar.slot', {
                url: '/place/:id?kidId',
                views: {
                    '@auth': 'slot'
                },
                resolve: {
                    slotsResolver: (Calendar, $transition$) => {
                        "ngInject";

                        return Calendar.getSlots($transition$.params().id, $transition$.params().kidId);
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('slot', {
        bindings: {
            slotsResolver: '<',
        },
        template,
        controller,
    })
    .name;
