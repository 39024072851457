import bookingComponent from './booking/booking.module';

import restaurantsComponent from './restaurants/restaurants.module';
import kidsComponent from './kids/kids.module';
import slotComponent from './slot/slot.module';

import controller from './calendar.component';
import template from './calendar.html';

export default angular
    .module('app.components.calendar', [
        bookingComponent,
        restaurantsComponent,
        kidsComponent,
        slotComponent,
    ])
    .filter('formatDate', () => {
        return (value, format = 'localized') => {
            let date = moment(value);

            switch(format) {
                case 'hour':
                    return date.format('HH:mm');
                case 'sql':
                    return date.format('YYYY-MM-DD');
                case 'localized':
                default:
                    return date.format('dddd D MMMM');
            }
        }
    })
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar', {
                url: '/calendar',
                data: {
                    flush: true,
                },
                component: 'calendar',
                resolve: {
                    placesResolver: (Calendar) => {
                        "ngInject";

                        return Calendar.getPlaces();
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('calendar', {
        bindings: {
            placesResolver: '<',
        },
        template,
        controller,
    })
    .name;
