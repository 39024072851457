import controller from './modal.component';
import template from './modal.html';
import service from './modal.service';

export default angular
    .module('app.core.modal', [])
    .service('Modal', service)
    .component('modal', {
        bindings: {
            mdName: '@',
            mdTitle: '<',
            mdSubtitle: '<',
            mdOptions: '<',
        },
        template,
        controller,
        transclude: true,
    })
    .name;
