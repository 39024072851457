class OnboardingController {
    constructor(User, Onboarding, $filter) {
        "ngInject";

        this.User = User;
        this.Onboarding = Onboarding;

        this.$translate = $filter('translate');
    }

    $onInit() {
    }

    $onDestroy() {
        this.Onboarding.cancel();
    }
}

export default OnboardingController;
