import { drop, forEach } from 'lodash-es';

class PreferencesController {
    constructor(User, Navigation, Calendar, Http, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Calendar = Calendar;
        this.Http = Http;

        this.$translate = $filter('translate');

        this.meals = [
            {
                id: 0,
                name: this.$translate('CALENDAR_RESTAURANTS_BREAKFAST'),
                slots: [],
                slot: null,
            },
            {
                id: 1,
                name: this.$translate('CALENDAR_RESTAURANTS_LUNCH'),
                slots: [],
                slot: null,
            },
            {
                id: 2,
                name: this.$translate('CALENDAR_RESTAURANTS_DINNER'),
                slots: [],
                slot: null,
            }
        ];

        this.halfBoardMeal = this.meals[2];
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('CALENDAR_RESTAURANTS_NO_BOOKING_ACTION'));

        this.slotsResolver.then((response) => {
            let meals = response.data;

            forEach(meals, (slots, index) => {
                this.meals[index].slots = slots.map((slot) => {
                    return {
                        id: slot.id,
                        startAt: slot.startAtTime,
                    };
                });
            });
        });
    }

    isUserHalfBoard() {
        return this.User.getUser().mode === 2;
    }

    isMealBreakfast(meal) {
        return meal.id === 0;
    }

    getHalfBoardMeals() {
        return drop(this.meals);
    }

    setHalfBoardMeal(meal) {
        this.halfBoardMeal.slot = null;
        this.halfBoardMeal = meal;
    }

    isMealSelectedForHalfBoard(meal) {
        return meal.id === this.halfBoardMeal.id;
    }

    isSubmittable() {
        let totalSlots = this.meals.reduce((acc, meal) => {
            let slot = (meal.slot !== null) ? 1 : 0;

            return acc + slot;
        }, 0);

        if (this.isUserHalfBoard()) {
            return totalSlots === this.getHalfBoardMeals().length;
        }

        return totalSlots === this.meals.length;
    }

    is2B() {
        return this.User.getUser().site.category === '2B';
    }

    submit() {
        let preferences = this.meals.map((meal) => {
            return {
                id: meal.id,
                slot: meal.slot,
            }
        });

        this.Http.post('/restaurant/config', { preferences }).then(() => {
            this.User.resetCache();
            this.Calendar.resetCache();

            this.Navigation.goBack();
        });
    }
}

export default PreferencesController;
