export default class PassService {
    constructor(Modal, $q) {
        "ngInject";

        this.Modal = Modal;

        this.$q = $q;

        this.reset();
    }

    reset() {
        this.userSlot = null;
        this.deferred = null;
    }

    show(options = {}) {
        let { userSlot } = options;

        this.reset();

        this.deferred = this.$q.defer();

        this.userSlot = userSlot;

        this.Modal.open('pass');

        return this.deferred.promise;
    }

    cancel() {
        if (this.deferred) {
            this.deferred.reject();
        }

        this.reset();

        this.Modal.close('pass');
    }
}
