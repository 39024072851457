import controller from './register.component';
import template from './register.html';

export default angular
    .module('app.components.calendar.kids.register', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar.kids.register', {
                url: '/register/:id?name&firstname&birthdate',
                views: {
                    '@auth': 'register'
                },
                resolve: {
                    dataResolver: (Kids, $transition$) => {
                        "ngInject";

                        return Kids.getClubData($transition$.params().id);
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            })
            .state('auth.calendar.kids.details', {
                url: '/details/:id',
                views: {
                    '@auth': 'register'
                },
                resolve: {
                    dataResolver: (Kids, $transition$) => {
                        "ngInject";

                        return Kids.getChildData($transition$.params().id);
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            })
        ;
    })
    .component('register', {
        bindings: {
            dataResolver: '<',
        },
        template,
        controller,
    })
    .name;
