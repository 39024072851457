const parameters = require(`../parameters/${APP_NAME}.js`);

import { zipObject, map } from 'lodash-es';

export default function translate($translateProvider) {
    let defaultLanguage = parameters.language ? parameters.language : 'fr';

    let languages = ['fr'];

    languages.forEach((lang) => {
        let translations = Object.assign({}, require(`./locales/fr.js`), require(`./locales/${lang}.js`));
        $translateProvider.translations(lang, translations);
    });

    let aliases = zipObject(map(languages, (language) => {
        return `${language}_*`;
    }), languages);

    aliases['*'] = defaultLanguage;

    $translateProvider
        .registerAvailableLanguageKeys(languages, aliases)
        .determinePreferredLanguage()
        .fallbackLanguage(defaultLanguage);
}

translate.$inject = ['$translateProvider'];
