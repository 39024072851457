import { forEach } from 'lodash-es';

import controller from './booking.component';
import template from './booking.html';

export default angular
    .module('app.components.calendar.booking', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar.restaurants.booking', {
                url: '/edit/:date/:type',
                params: {
                    date: {
                        type: 'string',
                    },
                    type: {
                        type: 'int',
                    },
                },
                views: {
                    '@auth': 'booking'
                },
                resolve: {
                    groupsAndSlotsResolver: (Calendar, $transition$) => {
                        "ngInject";

                        return Calendar.getGroupsAndSlotsForUserSlot($transition$.params().date, $transition$.params().type);
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('booking', {
        bindings: {
            groupsAndSlotsResolver: '<',
        },
        template,
        controller,
    })
    .name;
