import preferencesComponent from './preferences/preferences.module';

import controller from './restaurants.component';
import template from './restaurants.html';

export default angular
    .module('app.components.calendar.restaurants', [
        preferencesComponent,
    ])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar.restaurants', {
                url: '/restaurants',
                views: {
                    '@auth': 'restaurants'
                },
                resolve: {
                    userSlotsByDayResolver: (Calendar) => {
                        "ngInject";

                        return Calendar.getAllUserSlots();
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('restaurants', {
        bindings: {
            userSlotsByDayResolver: '<',
        },
        template,
        controller,
    })
    .name;
