import registerComponent from './register/register.module';

import controller from './kids.component';
import template from './kids.html';

export default angular
    .module('app.components.calendar.kids', [
        registerComponent,
    ])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.calendar.kids', {
                url: '/kids?mode&firstName&lastName',
                params: {
                    mode: {
                        type: 'string',
                    }
                },
                views: {
                    '@auth': 'kids'
                },
                resolve: {
                    placesResolver: (Calendar) => {
                        "ngInject";

                        return Calendar.getPlaces();
                    },
                },
                resolvePolicy: {
                    async: "NOWAIT",
                },
            });
    })
    .component('kids', {
        bindings: {
            placesResolver: '<',
        },
        template,
        controller,
    })
    .name;
