class LoginController {
    constructor(User, Onboarding, Modal, Alert, Config, $state, $templateCache, $filter) {
        "ngInject";

        this.User = User;
        this.Onboarding = Onboarding;
        this.Modal = Modal;
        this.Alert = Alert;
        this.Config = Config;

        this.$state = $state;
        this.$templateCache = $templateCache;
        this.$translate = $filter('translate');

        this.$templateCache.put('terms.html', require('./partials/terms.html'));
    }

    $onInit() {
        this.userHasAcceptedTerms = false;

        //FERAT
        //1765
    }

    login() {
        if (!this.userHasAcceptedTerms) {
            return false;
        }

        this.User.login(this.username, this.password).then(() => {
            this.$state.go(this.Config.home_state_name, {}, { reload: true, inherit: false });
        }).catch(() => {
            this.Alert.showToast({
                text: this.$translate('LOGIN_WRONG_CREDENTIALS'),
                duration: 3500,
            });
        });
    }
}

export default LoginController;
