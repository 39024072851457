class ViewportService {
    constructor($rootScope, $window, Config) {
        "ngInject";

        this.$rootScope = $rootScope;
        this.$window = $window;

        this.Config = Config;

        this.mediaQueryLists = {};
        this.results = {};
    }

    is(query) {
        if (this.Config.isMsie) {
            switch(query) {
                case 'xs':
                    return false;
                case 'gt-xs':
                    return true;
                case 'sm':
                    return false;
                case 'gt-sm':
                    return true;
                case 'md':
                    return true;
                case 'gt-md':
                    return true;
                case 'lg':
                    return true;
                case 'gt-lg':
                    return true;
                case 'xl':
                    return true;
            }

            return false;
        }

        switch(query) {
            case 'xs':
                query = '(max-width: 599px)';
                break;
            case 'gt-xs':
                query = '(min-width: 600px)';
                break;
            case 'sm':
                query = '(min-width: 600px) and (max-width: 959px)';
                break;
            case 'gt-sm':
                query = '(min-width: 960px)';
                break;
            case 'md':
                query = '(min-width: 960px) and (max-width: 1279px)';
                break;
            case 'gt-md':
                query = '(min-width: 1280px)';
                break;
            case 'lg':
                query = '(min-width: 1280px) and (max-width: 1919px)';
                break;
            case 'gt-lg':
                query = '(min-width: 1920px)';
                break;
            case 'xl':
                query = '(min-width: 1920px)';
                break;
        }

        let result = this.results[query];

        if (angular.isUndefined(result)) {
            result = this.mediaQueryLists[query];

            if (angular.isUndefined(result)) {
                result = this.mediaQueryLists[query] = this.$window.matchMedia(query);
            }

            result.addListener((query) => {
                this.$rootScope.$evalAsync(() => {
                    this.results[query.media] = !!query.matches;
                });
            });

            return (this.results[result.media] = !!result.matches);
        }

        return result;
    }
}

export default angular
    .module('app.services.viewport', [])
    .service('Viewport', ViewportService)
    .name;
