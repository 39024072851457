import controller from './auth.component';
import template from './auth.html';

export default angular
    .module('app.core.auth', [])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth', {
                url: '',
                abstract: true,
                component: 'auth',
            });
    })
    .component('auth', {
        bindings: {},
        template,
        controller,
    })
    .name;
