import { endsWith, find } from 'lodash-es';

class ProfileController {
    constructor(User, Navigation, Config, Modal, Onboarding, $state, $templateCache, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Modal = Modal;
        this.Config = Config;
        this.Onboarding = Onboarding;

        this.$state = $state;
        this.$templateCache = $templateCache;
        this.$translate = $filter('translate');

        this.$templateCache.put('terms.html', require('./../../core/login/partials/terms.html'));

        this.plans = [
            {
                id: 1,
                name: this.$translate('PROFILE_PLAN_RENTAL'),
            },
            {
                id: 2,
                name: this.$translate('PROFILE_PLAN_HALF_BOARD'),
            },
            {
                id: 3,
                name: this.$translate('PROFILE_PLAN_FULL_BOARD'),
            },
        ];
    }

    $onInit() {
        this.Navigation.setTitle(this.$translate('TAB_BAR_PROFILE'));
    }

    formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    formatPlan(mode) {
        return find(this.plans, (plan) => {
            return plan.id === mode;
        }).name;
    }

    formatClub(site) {
        return `${site.name}`;
    }

    logout() {
        this.User.logout().then(() => {
            this.$state.go(this.Config.login_state_name, {}, { reload: true, inherit: false });
        });
    }

    goToEdit() {
        this.$state.go('auth.profile.form-edit', {}, { reload: true, inherit: false })
    }
}

export default ProfileController;
