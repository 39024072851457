class UserService {
    constructor(Http, CacheFactory, Config, $httpParamSerializer, $q, localStorageService) {
        "ngInject";

        this.Http = Http;
        this.CacheFactory = CacheFactory;
        this.Config = Config;

        this.$httpParamSerializer = $httpParamSerializer;
        this.$q = $q;
        this.$localStorage = localStorageService;

        this.user = null;

        this.cache = this.CacheFactory.exists('user');

        if (this.cache) {
            this.cache = this.CacheFactory.get('user');
        }
        else {
            this.cache = this.CacheFactory.createCache('user');
        }

        this.resetCache();
    }

    isOverage() {
        return this.user.restaurantsAlreadyBookedCount >= this.user.restaurantsBookingIncluded;
    }

    isIncluded(type) {
        if (this.user.mode === 1) {
            return false;
        }

        if (this.user.mode === 2 && type === 1) {
            return false;
        }

        return true;
    }

    getUser() {
        return this.user;
    }

    getUserDetail() {
        return this.Http.get('/client/detail', {
            cache: this.cache,
        }).then((response) => {
            this.user = response.data;

            if (this.user === 'null') {
                this.user = null;
            }

            return this.user;
        });
    }

    login(lastName, bookingNumber) {
        return this.Http.post('/check/user', {
            name: lastName,
            bookingNumber: bookingNumber,
        }).then((response) => {
            this.setToken(response.data.token);
            this.setRefreshToken(response.data.refreshToken);
        });
    }

    refreshToken() {
        return this.Http.post('/token/refresh', {
            refresh_token: this.getRefreshToken(),
        }).then((response) => {
            console.log(response);
        });
    }

    logout() {
        this.removeRefreshToken();
        this.removeToken();

        this.CacheFactory.clearAll();

        return this.$q.resolve();
    }

    getToken() {
        return this.$localStorage.get('token');
    }

    setToken(token) {
        this.$localStorage.set('token', token);

        return token;
    }

    removeToken() {
        this.$localStorage.remove('token');
    }

    getRefreshToken() {
        return this.$localStorage.get('refreshToken');
    }

    setRefreshToken(refreshToken) {
        this.$localStorage.set('refreshToken', refreshToken);

        return refreshToken;
    }

    removeRefreshToken() {
        this.$localStorage.remove('refreshToken');
    }

    isLogged() {
        return this.$localStorage.get('token') !== null;
    }

    // refreshToken() {
    //     let refresh = this.getRefreshToken();
    //     let deferred = this.$q.defer();
    //     if (refresh) {
    //         this.Http.post('/token/refresh', {refresh_token: refresh}).then((response) => {
    //             this.setToken(response.data.token);
    //             this.setRefreshToken(response.data.refresh_token);
    //             deferred.resolve(response.data);
    //         }, () => {
    //             deferred.resolve({no_token: true});
    //         });
    //     } else {
    //         deferred.resolve({no_token: true});
    //     }
    //
    //     return deferred.promise;
    // }
    //
    //
    // setRefreshToken(refreshToken) {
    //     if (!ALLOW_COOKIES) {
    //         return this.$localStorage.set('refreshToken', refreshToken);
    //     }
    //
    //     return this.$cookies.put('refreshToken', refreshToken);
    // }
    //
    // getRefreshToken() {
    //     if (!ALLOW_COOKIES) {
    //         return this.$localStorage.get('refreshToken');
    //     }
    //
    //     if (this.$localStorage.get('refreshToken')) {
    //         this.$cookies.put('refreshToken', this.$localStorage.get('refreshToken'));
    //         this.$localStorage.remove('refreshToken');
    //     }
    //
    //     return this.$cookies.get('refreshToken');
    // }
    //
    // resetRefreshToken() {
    //     if (!ALLOW_COOKIES) {
    //         this.$localStorage.remove('token');
    //         return;
    //     }
    //
    //     this.$cookies.remove('refreshToken');
    //     this.$cookies.remove('refreshToken', {});
    // }

    getCache() {
        return this.cache;
    }

    resetCache() {
        if (typeof this.cache === 'object') {
            this.cache.removeAll();
        }
    }
}

export default angular
    .module('app.services.user', [])
    .service('User', UserService)
    .name;
