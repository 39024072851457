class PassController {
    constructor(User, Pass, $filter) {
        "ngInject";

        this.User = User;
        this.Pass = Pass;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');
    }

    $onInit() {
    }

    getBackgroundColor() {
        switch(this.User.getUser().mode) {
            case 3:
                return '#971C5F';
            case 1:
            case 2:
            default:
                return '#99C305';
        }
    }

    $onDestroy() {
        this.Pass.cancel();
    }
}

export default PassController;
