import { forEach } from 'lodash-es';

class ConfigService {
    constructor(localStorageService) {
        "ngInject";

        this.$localStorage = localStorageService;
        this.currentPlatform = this.$localStorage.get('currentPlatform');

        forEach(this.currentPlatform, (value, key) => {
            this[key] = value;
        });
    }

    setCurrentPlatform(currentPlatform) {
        this.currentPlatform = currentPlatform;
        this.$localStorage.set('currentPlatform', currentPlatform);

        forEach(this.currentPlatform, (value, key) => {
            this[key] = value;
        });
    }
}

export default angular
    .module('app.services.config', [])
    .service('Config', ConfigService)
    .name;
