import profileFormComponent from './profile-form/profile-form.module';

import controller from './profile.component';
import template from './profile.html';

export default angular
    .module('app.components.profile', [
        profileFormComponent,
    ])
    .config(($stateProvider) => {
        "ngInject";

        $stateProvider
            .state('auth.profile', {
                url: '/profile',
                data: {
                    flush: true,
                },
                component: 'profile',
            });
    })
    .component('profile', {
        bindings: {},
        template,
        controller,
    })
    .name;
