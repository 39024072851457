import controller from './pass.component';
import template from './pass.html';
import service from './pass.service';

export default angular
    .module('app.core.modals.pass', [])
    .service('Pass', service)
    .component('pass', {
        bindings: {},
        template,
        controller,
    })
    .name;
