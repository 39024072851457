class RestaurantsController {
    constructor(User, Navigation, Pass, $state, $filter) {
        "ngInject";

        this.User = User;
        this.Navigation = Navigation;
        this.Pass = Pass;

        this.$state = $state;

        this.$translate = $filter('translate');
        this.formatDate = $filter('formatDate');

        this.userSlotsByDay = [];
        this.meals = [];
    }

    $onInit() {
        if (this.User.getUser().mode === 1) {
            this.meals = [
                {
                    id: 1,
                    name: this.$translate('CALENDAR_RESTAURANTS_LUNCH'),
                },
                {
                    id: 2,
                    name: this.$translate('CALENDAR_RESTAURANTS_DINNER'),
                }
            ];
        } else {
            this.meals = [
                {
                    id: 0,
                    name: this.$translate('CALENDAR_RESTAURANTS_BREAKFAST'),
                },
                {
                    id: 1,
                    name: this.$translate('CALENDAR_RESTAURANTS_LUNCH'),
                },
                {
                    id: 2,
                    name: this.$translate('CALENDAR_RESTAURANTS_DINNER'),
                }
            ];
        }

        this.Navigation.setTitle(this.$translate('CALENDAR_RESTAURANTS_TITLE'));

        this.userSlotsByDayResolver.then((userSlotsByDay) => {
            this.userSlotsByDay = userSlotsByDay.sort((a,b) => a.date > b.date ? 1 : -1);
        });
    }

    sortByDate(arr) {
        return arr.sort((a,b) => a.slot.startAt > b.slot.startAt ? 1 : -1);
    }

    getUserSlotByType(userSlots, type) {
        return userSlots.filter((userSlot) => {
            return userSlot.slot.slotableConfig.type === type;
        })
    }

    subtractTenMinutes(date) {
        return moment(date).subtract(10, 'minutes').format();
    }

    isRestaurantBooked() {
        return this.User.getUser().restaurantBooked;
    }

    getName(userSlot) {
        return this.meals.find((meal) => {
            return meal.id === userSlot.slot.slotableConfig.type;
        }).name;
    }

    isSpecialRestaurant(userSlot) {
        return userSlot.slot.slotableConfig.slotable.type === 'RESTAURANT' && !userSlot.slot.slotableConfig.slotable.default;
    }

    edit(userSlotsForDay, type) {
        let userSlot = this.getUserSlotByType(userSlotsForDay.userSlots, type)[0];

        if (userSlot && this.isSpecialRestaurant(userSlot)) {
            this.$state.go('auth.calendar.slot', {
                id: userSlot.slot.slotableConfig.slotable.id
            }, { reload: true, inherit: false });
        }
        else {
            this.$state.go('auth.calendar.restaurants.booking', {
                date: this.formatDate(userSlotsForDay.date, 'sql'),
                type: type
            }, { reload: true, inherit: false });
        }
    }
}

export default RestaurantsController;
