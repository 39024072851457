
class AuthController {
    constructor(User, Modal, Navigation, Config, $state, $filter) {
        "ngInject";

        this.User = User;
        this.Modal = Modal;
        this.Navigation = Navigation;
        this.Config = Config;

        this.$state = $state;
        this.$translate = $filter('translate');
    }

    $onInit() {
    }

    isActive(route) {
        console.log('route', route, this.$state.includes(route));
        return this.$state.includes(route);
    }
}

export default AuthController;
