"use strict;"

export default class AlertService {
    constructor($rootScope, $filter, $q) {
        "ngInject";

        this.$rootScope = $rootScope;
        this.$q = $q;
        this.translate = $filter('translate');

        this.alerts = [];
    }

    showActionSheet(params) {
        let { title = null, text = null, buttons = [], cancel = () => {} } = params;

        this.alerts.push({
            type: 'actionsheet',
            title: title,
            text: text,
            buttons: buttons,
            cancelButton: {
                label: this.translate('TAG_DELETE_CONFIRM_CANCEL'),
                classes: [],
                callback: cancel,
            },
        });

        this.$rootScope.$broadcast('alert:added', 'actionSheet');
    }

    showConfirm(params) {
        let { title = null, text = null, classes = [], button = null } = params;

        return this.$q((resolve, reject) => {
            this.alerts.push({
                type: 'confirm',
                title: title,
                text: text,
                classes: classes,
                button: button,
                confirm: () => { return resolve(); },
                cancel: () => { return reject(new Error('cancel')); },
            });

            this.$rootScope.$broadcast('alert:added', 'prompt');
        });
    }

    showPrompt(params) {
        let { title = null, text = null, button = {}, placeholder = null, defaultValue = null, clickOutsideToDismiss = true } = params;

        return this.$q((resolve, reject) => {
            this.alerts.push({
                type: 'prompt',
                title: title,
                text: text,
                button: button,
                placeholder: placeholder,
                defaultValue: defaultValue,
                clickOutsideToDismiss: clickOutsideToDismiss,
                confirm: (value) => { return resolve(value); },
                cancel: () => { return reject(new Error('cancel')); },
            });

            this.$rootScope.$broadcast('alert:added', 'prompt');
        });
    }

    showPromptNumber(params) {
        let { title = null, text = null, button = {}, defaultValue = null, clickOutsideToDismiss = true, maxValue = null, minValue = 1 } = params;

        return this.$q((resolve, reject) => {
            this.alerts.push({
                type: 'prompt_number',
                title: title,
                text: text,
                button: button,
                defaultValue: defaultValue,
                maxValue: maxValue,
                minValue: minValue,
                clickOutsideToDismiss: clickOutsideToDismiss,
                confirm: (value) => { return resolve(value); },
                cancel: () => { return reject(new Error('cancel')); },
            });

            this.$rootScope.$broadcast('alert:added', 'prompt');
        });
    }

    showPromptSelect(params) {
        let { title = null, text = null, button = {}, placeholder = null, defaultValue = null, clickOutsideToDismiss = true, choices = [] } = params;

        return this.$q((resolve, reject) => {
            this.alerts.push({
                type: 'prompt_select',
                title: title,
                text: text,
                button: button,
                placeholder: placeholder,
                defaultValue: defaultValue,
                choices: choices,
                clickOutsideToDismiss: clickOutsideToDismiss,
                confirm: (value) => { return resolve(value); },
                cancel: () => { return reject(new Error('cancel')); },
            });

            this.$rootScope.$broadcast('alert:added', 'prompt');
        });
    }

    showPromptDate(params) {
        let { title = null, text = null, button = {}, placeholder = null, clickOutsideToDismiss = true, defaultValue = null} = params;

        return this.$q((resolve, reject) => {
            this.alerts.push({
                type: 'prompt_date',
                title: title,
                text: text,
                button: button,
                placeholder: placeholder,
                defaultValue: defaultValue,
                clickOutsideToDismiss: clickOutsideToDismiss,
                confirm: (value) => { return resolve(value.unix()); },
                cancel: () => { return reject(new Error('cancel')); },
            });

            this.$rootScope.$broadcast('alert:added', 'prompt');
        });
    }

    showTextArea(params) {
        let { title = null, textAreas = [], button = {} } = params;

        return this.$q((resolve, reject) => {
            this.alerts.push({
                type: 'textarea',
                title: title,
                textAreas: textAreas,
                button: button,
                confirm: (value) => { return resolve(value); },
                cancel: () => { return reject(new Error('cancel')); },
            });

            this.$rootScope.$broadcast('alert:added', 'prompt');
        });
    }

    showToast(params) {
        let { title = null, text = null, icon = {}, duration = 3000 } = params;

        this.alerts.push({
            type: 'toast',
            title: title,
            text: text,
            icon: icon,
            duration: duration,
        });

        this.$rootScope.$broadcast('alert:added', 'toast');
    }

    hideToast() {
        this.$rootScope.$broadcast('alert:toast:hide');
    }
}
