class KidsService {
    constructor(Http, CacheFactory, Config, $q) {
        "ngInject";

        this.Http = Http;
        this.CacheFactory = CacheFactory;
        this.Config = Config;
        this.$q = $q;

        this.requirements = [
            {
                type: 'CHICKS',
                minimum_age: 3,
                maximum_age: 35,
            },
            {
                type: 'FOXES',
                minimum_age: 3 * 12,
                maximum_age: 5 * 12 + 11,
            },
            {
                type: 'PIRATES',
                minimum_age: 6 * 12,
                maximum_age: 10 * 12 + 11,
            },
            {
                type: 'JUNIORS',
                minimum_age: 11 * 12,
                maximum_age: 13 * 12 + 11,
            },
            {
                type: 'TEENS',
                minimum_age: 14 * 12,
                maximum_age: 17 * 12 + 11,
            },
        ];

        this.cache = this.CacheFactory.exists('kids');

        if (this.cache) {
            this.cache = this.CacheFactory.get('kids');
        }
        else {
            this.cache = this.CacheFactory.createCache('kids');
        }

        this.resetCache();
    }

    getRequirements() {
        return this.requirements;
    }

    getClubData(clubId) {
        return this.Http.get(`/kids/club/${clubId}`, {
            cache: this.cache,
        }).then((response) => response.data);
    }

    getChildData(childId) {
        return this.Http.get(`/kids/details/${childId}`, {
            cache: this.cache,
        }).then((response) => response.data);
    }

    postKidForm(clubId, form) {
        return this.Http.post(`/kids/add`, {
            form,
            clubId,
        });
    }

    getCache() {
        return this.cache;
    }

    resetCache() {
        if (typeof this.cache === 'object') {
            this.cache.removeAll();
        }
    }
}

export default angular
    .module('app.services.kids', [])
    .service('Kids', KidsService)
    .name;
