import { get } from 'lodash-es';

export default ($injector, $state, $q, $templateCache) => {
    "ngInject";

    // let isBusy = false;

    return {
        request: (request) => {
            let User = $injector.get('User');
            let Config = $injector.get('Config');

            request.headers = angular.extend({}, request.headers);
            request.headers.Authorization = `Bearer ${User.getToken()}`;
            //request.headers.locale = get(User.getUser(), 'language', Config.language);

            if (request.url &&
                !request.url.startsWith('http') &&
                !request.url.startsWith('file') &&
                !request.url.startsWith('_capacitor_') &&
                !request.url.startsWith('cdvfile')
            ) {
                if (request.cache !== $templateCache) {
                    request.url = `${Config.api_url}${request.url}`;

                    if (Config.isMsie && request.method === 'GET') {
                        let timestamp = new Date().getTime();

                        if (request.url.indexOf('?') !== -1) {
                            request.url = `${request.url}&t=${timestamp}`;
                        }
                        else {
                            request.url = `${request.url}?t=${timestamp}`;
                        }
                    }
                }
            }

            return request;
        },
        responseError: (response) => {
            if (response.config &&
                response.config.url &&
                response.config.url.includes('login_check')
            ) {
                return $q.reject(response);
            }

            let User = $injector.get('User');
            let Config = $injector.get('Config');

            if (response.status === 401) {
                User.logout().then(() => {
                    $state.go(Config.login_state_name, {}, { reload: true, inherit: false });
                });

                if (!User.isLogged() || !User.getRefreshToken()) {
                    User.logout(() => {
                        $state.go(Config.login_state_name, {}, { reload: true, inherit: false });
                    });
                }
                else {
                    console.log('refresh token');
                    User.refreshToken().then((response) => {
                        console.log(response);
                    });
                }
            }

            return $q.reject(response);
        }
    }
};
